.JobCard { 
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin:20px 0px;
}

.JobCard-Info{
  display:flex;
  justify-content: space-between;
}

.JobCard-Button{
  align-self: flex-end;
}

.JobCard-Title {
margin-bottom: 10px;
}

.JobCard-Description{
  margin-left: 20px;
  margin-top: 20px;
}