.CompanyCard {
  margin: 20px 0px;
  padding: 5px
}

.CompanyCard:hover {
  color: #007BFF;
  cursor: pointer;

}
.CompanyCard-Name {
  padding-left: 15px;
  margin-top: 25px;
}

.CompanyCard-Description{
  padding-left: 20px
}

.CompanyCard-NameImg {
  display:flex; 
  justify-content:space-between;
  padding: 5px;
}

.Company-Img {
  max-width:70px
}
