.LoginRegister-Form {
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  border-radius: .25rem;
  max-width: 500px;
  margin: 0 auto;
}

.LoginRegister-Form Label {
  text-transform: capitalize;
}